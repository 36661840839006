import React, {Fragment, useContext} from 'react';
import {Alert, Button, Col, Form, Input, Row, Space, Typography} from 'antd';
import {navigate} from 'gatsby';
import {Routes} from '~src/utils/routes';
import styled from 'styled-components';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {LocaleContext} from '~src/context';
import * as colors from '@ant-design/colors';

const {Title} = Typography;

const IconStyled = {
  color: colors.presetPrimaryColors.blue,
};

interface IContentProps {}

const validateMessages = {
  required: 'Обязательное поле',
};
interface IContentProps {}

const Content: React.FC<IContentProps> = () => {
  const {dispatch, auth} = useStoreon<IState, IEvents>('auth');

  const {
    pages: {
      admin: {auth: authContext},
    },
  } = useContext(LocaleContext);

  // TODO: разобраться с типами - в AntD по дефолту стоит any
  const handleResetSubmit = values => {
    dispatch('auth/recovery-end', values);
  };

  return (
    <RowStyled gutter={[20, 20]}>
      {auth.recoveryEndError && (
        <Col span={24}>
          <Alert type={'error'} message={auth.recoveryEndError} />
        </Col>
      )}
      {!auth.isLoadedRecoveryEndPassword && (
        <Col span={24}>
          <Row>
            <Col span={24}>
              <TitleStyled level={4}>{authContext.recoveryEndScreen.title}</TitleStyled>
            </Col>
            <Col span={24}>
              <FormStyled
                name="reset-form"
                validateMessages={validateMessages}
                onFinish={handleResetSubmit}
                size={'large'}
              >
                <Form.Item
                  name={authContext.recoveryEndScreen.passwordInput.name}
                  validateTrigger={['onBlur', 'onSubmit']}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    type={authContext.recoveryEndScreen.passwordInput.type}
                    prefix={<authContext.recoveryEndScreen.passwordInput.iconComponent style={IconStyled} />}
                    name={authContext.recoveryEndScreen.passwordInput.name}
                    placeholder={authContext.recoveryEndScreen.passwordInput.placeholder}
                  />
                </Form.Item>
                <ButtonStyled block type="primary" htmlType="submit">
                  {authContext.recoveryEndScreen.button}
                </ButtonStyled>
              </FormStyled>
            </Col>
          </Row>
        </Col>
      )}
      {auth.isLoadedRecoveryEndPassword && (
        <Fragment>
          <Col span={24}>
            <authContext.successScreen.Icon style={{fontSize: 50, color: colors.presetPrimaryColors.green}} />
          </Col>
          <Col span={24}>
            <TitleStyled level={4}>{authContext.recoveryEndScreen.successTitle}</TitleStyled>
          </Col>
          <Col span={24}>
            <Button type={'primary'} size={'large'} onClick={() => navigate(Routes.signIn)}>
              {authContext.recoveryEndScreen.successButton}
            </Button>
          </Col>
        </Fragment>
      )}
    </RowStyled>
  );
};

const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  &:last-of-type(FormItemStyled) {
    margin-bottom: 0;
  }
`;

const TitleStyled = styled(Title)`
  margin-bottom: 30px !important;
  display: flex;
  justify-content: center;
`;

const RowStyled = styled(Row)`
  text-align: center;
`;

const ButtonStyled = styled(Button)`
  margin-bottom: 10px;
`;

export default Content;
